const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/terms"],
    exact: true,
    component: "Terms",
  },
  {
    path: ["/privacy"],
    exact: true,
    component: "Privacy",
  },
  {
    path: ["/cookie"],
    exact: true,
    component: "Cookie",
  },
  // {
  //   path: ["/recommendation/:id"],
  //   exact: true,
  //   component: "Recommendation",
  // },
];

export default routes;

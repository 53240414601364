import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import "flag-icons/css/flag-icons.min.css";
import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: { t: TFunction }) => {
  const handleChange = (language: string) => {
    localStorage.setItem("language", language);
    i18n.changeLanguage(language);
  };

  const scrollTo = (id: string) => {
    const element = document.getElementById(id) as HTMLDivElement;
    element.scrollIntoView({
      behavior: "smooth",
    });
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
        style={{ marginRight: "10px" }}
      >
        <SvgIcon
          src={src}
          width="25px"
          height="25px"
          style={{ marginRight: "10px" }}
        />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Contact")}</Language>
              <Large to="/">{t("Tell us everything")}</Large>
              <Para>
                {t(`Do you have any question? Feel free to reach out.`)}
              </Para>
              <a href="mailto:info@keepsecure.me">
                <Chat>{t(`Let's Chat`)}</Chat>
              </a>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t("Policy")}</Title>
              <Large to="/terms">{t("Terms of Use")}</Large>
              <Large to="/privacy">{t("Privacy Policy")}</Large>
              <Large to="/cookie">{t("Cookie Policy")}</Large>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Title>{t("Us")}</Title>
              <Large to="/" onClick={() => scrollTo("about")}>
                {t("About")}
              </Large>
              <a
                href="https://blog.keepsecure.me"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Large as="span">{t("Blog")}</Large>
              </a>
              {/* <Large to="/">{t("Press")}</Large>
              <Large to="/">{t("Careers & Culture")}</Large> */}
            </Col>
          </Row>
          <Row justify="space-between">
            <Col lg={6} md={6} sm={12} xs={12}>
              <Label htmlFor="select-lang">{t("Language")}</Label>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange("en")}>
                  <span
                    className="fi fi-us"
                    style={{ width: "30px", height: "30px" }}
                  ></span>
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("es")}>
                  <span
                    className="fi fi-es"
                    style={{ width: "30px", height: "30px" }}
                  ></span>
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("tr")}>
                  <span
                    className="fi fi-tr"
                    style={{ width: "30px", height: "30px" }}
                  ></span>
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("de")}>
                  <span
                    className="fi fi-de"
                    style={{ width: "30px", height: "30px" }}
                  ></span>
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("fr")}>
                  <span
                    className="fi fi-fr"
                    style={{ width: "30px", height: "30px" }}
                  ></span>
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="logo.svg"
                  aria-label="homepage"
                  width="180px"
                  height="64px"
                />
              </LogoContainer>
            </NavLink>
            <FooterContainer
              style={{ justifyContent: "flex-end", width: "auto" }}
            >
              <SocialLink href="https://x.com/keepsecure" src="x.svg" />
              <SocialLink
                href="https://instagram.com/keepsecure"
                src="instagram.svg"
              />
              <SocialLink
                href="https://facebook.com/keepsecure"
                src="facebook.svg"
              />
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
